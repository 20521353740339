import React from 'react';
import Layout from '../../components/layout';
import Questions from '../../components/Questions';

const MedizinischesPeeling = ({ data, location, ...props }) => {
	return (
		<Layout
			location={location}
			title="Medizinisches Peeling"
			noSlider
			{...props}
		>
			<p>
				Diese Technik gehört zum Standard der modernen ästhetischen
				Dermatologie.{' '}
			</p>
			<p>
				Bei einem medizinisch chemischen Peeling werden flüssige
				Substanzen in definierter Menge und Wirkstärke auf die Haut
				aufgetragen. Diese Säuren unterschiedlicher Herkunft und
				Konzentration führen zu eine Hautabschilferung unterschiedlicher
				Tiefe. Durch den Schäleffekt entsteht eine neue, glatte, frische
				Haut. Fältchen werden deutlich vermindert, Poren verkleinert,
				Narben verbessert, Pigmentflecken aufgelöst um nur einige
				Ergebnisse zu erwähnen.
			</p>
			<p>
				Je stärker der Effekt an der Haut ist, umso länger sind die
				Ausfallzeiten. Bei einem mitteltiefem Peel sind ca. 2 Wochen zu
				rechnen.{' '}
			</p>
			<p>Doch sind die Effekte wirklich großartig und langanhaltend.</p>

			<Questions title="Medizinisches Peeling" prefix="um" />
		</Layout>
	);
};

export default MedizinischesPeeling;
